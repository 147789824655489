import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

const StyledForm = styled.form`
  input,
  textarea {
    padding: 20px 18px;
    color: ${colors.charcoal};
    font-family: ${fonts.primary};
    font-size: 16px;
    border: 2px solid ${colors.gray};
    border-radius: 5px;
    box-sizing: border-box;

    &::placeholder {
      opacity: 1;
    }
  }

  textarea {
    height: 160px;
    resize: none;

    ${breakpoint.small`
      height: 200px;
    `}
  }

  .msg-error {
     font-size: 17px;
     font-weight: 500;
     padding-left: 10px;
     color: ${colors.orange};
  }
  
  .form__group {
    display: flex;
    justify-content: space-between;
	flex: auto;
    flex-flow: wrap;
    margin: 5px 0;
    
      ${breakpoint.small`
        margin: 10px 0;
      `}

    label {
      width: 100%;
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 1.2em;
    }

    input,
    textarea {
      width: 100%;
      margin-bottom: 10px;

      ${breakpoint.small`
        margin: 0;
      `}
    }
  }

  .form-element {
    display: flex;
    flex-direction: column;
  }

  .form__group div {
    padding: 0 .5em;
    flex: 1 1 80%;
  }

  .form__submit {
    text-align: center;

    button[type="submit"] {
      width: 100px;
      height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: ${colors.orange};
      color: ${colors.charcoal};
      font-family: ${fonts.primary};
      font-size: 14px;
      font-weight: 700;
      border-radius: 22px;
      transition: all 0.3s;

      &:hover {
        background-color: ${colors.charcoal};
        color: ${colors.white};
      }
    }
    
    button[type="button"] {
      width: 250px;
      height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: ${colors.orange};
      color: ${colors.charcoal};
      font-family: ${fonts.primary};
      font-size: 14px;
      font-weight: 700;
      border-radius: 22px;
      transition: all 0.3s;

      &:hover {
        background-color: ${colors.charcoal};
        color: ${colors.white};
      }
    }
  }
`

export default StyledForm
