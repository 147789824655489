import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/visby-university/hero"
import LoginForm from "./screens/visby-university/login"
import RespiSections from "./screens/visby-university/respi-all-sections"
import SexualHealthSections from "./screens/visby-university/sti-all-sections"
import Introduction from "./screens/visby-university/introduction"

class VisbyDownloads extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: "all",
		}
	}

	componentDidMount() {
		var filtby = this.state.filter;
		if (this.props.filterby)
			filtby = this.props.filterby;

		this.handleFilterChange("devices", filtby, null);
	}

	handleFilterChange = (type, value, event) => {
		const inactiveFilters = document.querySelectorAll("[data-filter]")
		inactiveFilters.forEach(filter => {
			filter.classList.remove("active")
		})


		if (value === "all") {
			const elementsToFilter = document.querySelectorAll(
				"[data-filter-type='" + type + "']"
			)

			elementsToFilter.forEach(post => {
				post.classList.remove("visibleBlock")
				post.classList.add("invisible")
			})
		} else {

		document.getElementById('introduction').style.display = "none" 
		document.getElementById('filter-buttons').style.display = "block" 

		const activeFilter = document.querySelector(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilter.classList.add("active")

		const elementsToFilter = document.querySelectorAll(
			"[data-filter-type='" + type + "'][data-filter='" + value + "']"
		)
		const previousElements = document.querySelectorAll(
			"[data-filter-type='" + type + "']"
		)

		previousElements.forEach(post => {
			post.classList.remove("visibleBlock")
			post.classList.add("invisible")
		})

		elementsToFilter.forEach(post => {
			post.classList.remove("invisible")
			post.classList.add("visibleBlock")
		})
	}
	}

	removeAllSections = (type, value) => {
		const inactiveSections = document.querySelectorAll("[data-filter-type='" + type + "'][data-filter='" + value + "'][data-section]")
		inactiveSections.forEach(section => {
			section.classList.remove("visibleBlock")
		})
	}

	//add a section for the two product images

	render = props => (
		<React.Fragment>
			<Hero filterFunction={this.handleFilterChange}  removeAllSections={this.removeAllSections} />
			<LoginForm />
			<Introduction filterFunction={this.handleFilterChange} removeAllSections={this.removeAllSections} />
			<SexualHealthSections />
			<RespiSections />
		</React.Fragment>
	)
}

export default VisbyDownloads


export const Head = () => (
	<Seo
		title="Visby University"
		description="Download point of care resources for our Visby Medical Sexual Health Test and our Visby Medical Respiratory Health Test."
	/>
)
