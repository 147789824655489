import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import Form from "components/form"

const StyledLoginForm = styled.section`
	display: ${props => (props.viewAllowed ? "none" : "block")};
	max-width: 1100px;
	padding: 64px 20px;  
	margin: 0 auto;

  ${Container} {
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0%;
    padding: 0;

	  ${breakpoint.medium`
		column-gap: 5%;
	  `}
  }


	.landing__form {
		background: ${colors.white};
		flex: 1 0 auto;
		margin-bottom: 25px;
		width: 100%;
		padding: 20px;
			
		.form__group  {
			flex: 1 1 auto;
		}

		h2, p {
			padding: 0 11px;
		}

		.form__submit {
			text-align: left;
			margin-left: 10px;

			  button[type="submit"] {
				width: 97%;
				max-width: 196px;
				margin-block: 10px;
			  }
		}    

		input {
			font-size: 14px;	
			padding: 15px;
		}

		input::placeholder {
			color: ${colors.charcoal};
		}

		.msg-confirm {
			margin: 10px;
		}
		
		.showMessage {
			display: block;
			font-size: 18px;
			margin-left: 16px;
			color: ${colors.orange};
		}
		.noShowMessage { display: none;}


		.msg-error {
			color: ${colors.orange};
		}


		.google__privacy {
			font-size: 12px;
			line-height: 1.4em;
			margin: 20px 0;
			color: ${colors.graySelect};

			a {
			  color: ${colors.orange};

			&:hover {
			  color: ${colors.purpleDark};
			  }
			}
		}

	}

`

export default class LoginSection extends React.Component {
	state = {
		pwEntered: "",
		incorrectPW: "",
		viewAllowed: false
	}

	componentDidMount = props => {
		if (this.props.viewAllowed) {
			this.setState({
				viewAllowed: true
			})
			document.getElementById('filter-buttons').style.display = "block"
			document.getElementById('loginform').style.display = "none"
		} else {
			this.setState({
				viewAllowed: false
			})
		}
	}

	handleInputChange = event => {
		const target = event.target
		const value = target.value
		const name = target.name
		this.setState({
			[name]: value
		})
	}


	handleSubmit = event => {
		event.preventDefault()

		if (this.state.pwEntered === "Visby-POC") {
			this.setState({
				viewAllowed: true,
				incorrectPW: ""
			})
			document.getElementById('filter-buttons').style.display = "none"
			document.getElementById('loginform').style.display = "none"
			document.getElementById('introduction').style.display = "block"
			document.getElementById('subscribe-downloads').style.display = "block"
		} else {
			this.setState({
				viewAllowed: false,
				incorrectPW: "Password is incorrect. Please try again."
			})
		}
	}

	render = props => (
		<StyledLoginForm
			id="loginform"
			viewAllowed={this.state.viewAllowed}
		>
			<div className="landing__form">
				<h2>Login</h2>
				<p>Please enter your password to access Visby University.</p>
				<p className="msg-error">{this.state.incorrectPW}</p>
				<Form onSubmit={this.handleSubmit}>
					<div className="form__group">
						<div>
							<input
								id="pwEntered"
								name="pwEntered"
								type="password"
								placeholder="Password*"
								aria-label="Password*"
								onChange={this.handleInputChange}
								required
							/>
						</div>
					</div>

					<div className="form__submit">
						<button type="submit">
							Submit
						</button>
					</div>
				</Form>
			</div>
		</StyledLoginForm>
	)
}

