import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { Button } from "components/buttons"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  text-align: center;
`

const StyledIntroduction = styled.section`
  display: ${props => (props.viewAllowed ? "block" : "none")};
  position: relative;
  padding: 90px 0;
  border-bottom-width: 0;
  max-width: 1100px;
  margin: 0 auto;

  p { 
    font-size: 17px; 
    line-height: 1.3em;
    text-align: center;
    }

  ${Button} {
  	min-width: 220px;
  	width: auto;
	}

  .feature {
		padding-top: 30px;
	}
	
	.styledFeature {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		gap: 20px;
		text-align: center;
	
	  ${breakpoint.small`
		width: 100%;
		flex-wrap: nowrap;
	  `}

	.order1 {
		order: 1;
		
	  ${breakpoint.medium`
			order: 1;
	  `}
	}

	.order2 {
		order: 2;
	  ${breakpoint.medium`
			order: 2;
	  `}
	}


  .feature__image {
    width: 95%;
    max-width: 400px;
    height: auto;
    margin: 0px auto 50px;
    padding: 40px 0 0;
    color: ${colors.charcoal};    
    border: 3px solid ${colors.orange}; 
    border-radius: 20px;
    background-color: ${colors.white}; 

    .gatsby-image-wrapper {
      margin-inline: 10px;
    }

    ${breakpoint.medium`
		  max-width: 500px;
    `}
    
    &:hover {
      color: ${colors.white}; 
      border: 3px solid ${colors.purpleMain}; 
      background-color: ${colors.grayLight}; 
      cursor: pointer;
    }

    &:hover > h2 {
      background-color: ${colors.purpleMain}; 
    }

    &:hover > .gatsby-image-wrapper {
      opacity: 0.8; 
    }


    h2 {
      font-size: 25px;
      letter-spacing: -0.02em;
      font-weight: 700;
      margin: 35px 0 0;
      background-color: ${colors.orange}; 
      padding: 15px 0 10px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      
      ${breakpoint.small`
      font-size: 28px;
      `}
  
      &::before {
        content: ">>";
        position: relative;
        top: 2px;
        margin-right: 12px;
        letter-spacing: -6px;
        font-size: 30px;
      }
  
    }
  }


  .feature__content {
    width: 100%;
    margin: 2px auto 40px;

    ${breakpoint.medium`
		width: 45%;
		margin: 5px auto;
    `}
    }


`

const Introduction = props => {
  return (
    <StyledIntroduction
      id="introduction"
      viewAllowed={props.viewAllowed}
      data-filter="introduction" data-filter-type="devices"
    >
      <StyledContainer>
        <div className="feature">
          <div className="styledFeature">
            <div
              className="feature__image order1"
              onClick={() => { props.filterFunction("devices", "sexual-health"); props.removeAllSections("devices", "sexual-health");} }
              onKeyDown={() => { props.filterFunction("devices", "sexual-health"); props.removeAllSections("devices", "sexual-health");} }
              role="button" tabIndex="0"
            ><StaticImage
                src="../../../assets/images/visby-university/sexual-health-downloads.png"
                width={400}
                alt="Visby Medical Sexual Health Test"
              />
              <h2>Sexual Health Test</h2>
            </div>
            <div
              className="feature__image order2"
              onClick={() => { props.filterFunction("devices", "respiratory"); props.removeAllSections("devices", "respiratory");} }
              onKeyDown={() => { props.filterFunction("devices", "respiratory"); props.removeAllSections("devices", "respiratory");} }
              role="button" tabIndex="0"
            ><StaticImage
                src="../../../assets/images/visby-university/respiratory-downloads.png"
                width={400}
                alt="Visby Medical Respiratory Health Test"
              />
              <h2>Respiratory Health Test</h2>
            </div>
          </div>
          <p>To review resources, please select a  product.</p>
        </div>
      </StyledContainer>
    </StyledIntroduction>
  )
}

export default Introduction
