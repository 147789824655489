import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledHero = styled.div`
	background-color: ${colors.purpleDark};
	color: ${colors.white};
	text-align: center;

  ${Container} {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 290px;

  ${breakpoint.medium`
  		height: 320px;
  `}
}
#filter-buttons, #subscribe-downloads {
	display: ${props => (props.viewAllowed ? "block" : "none")};
}

  .filters {
    margin-top: 24px;
    font-size: 14px;
  }

  .filter {
    display: inline-block;
    padding: 8px;
    margin: 2px;
    border: 1px solid ${colors.white};
    border-radius: 23px;
    text-align: center;
    transition: all 0.6s;
    cursor: pointer;
    min-width: 180px;
    font-weight: 500;

  ${breakpoint.small`
    padding: 10px 16px;
    margin: 5px;
  `}

  ${breakpoint.medium`
    margin: 0 10px 20px;
    min-width: 200px;
  `}


    &:hover,
    &.active {
      background-color: ${colors.orange};
      border-color: ${colors.orange};
    }
  }
  
  h1 { 
    color: ${colors.white}; 
    padding-bottom: 0;

  ${breakpoint.medium`
    padding-bottom: 1rem;
  `}
  }
  
  a { color: ${colors.orange};}
  a:hover { color: ${colors.white};}
  
  `

const Hero = props => {
  return (
    <StyledHero data-aos="fade-in">
      <Container>
        <h1>Welcome to Visby University</h1>
		    <p>Training and Implementation Resources</p>
        <p id="subscribe-downloads"><a href="/visby-university-updates/" target="_blank">Subscribe</a> for Visby updates.</p>
        <div className="filters" id="filter-buttons">
          <button
            type="button"
            data-filter
            data-filter-type="devices"
            data-target="sexual-health"
            className="filter"
            onClick={() => { props.filterFunction("devices", "sexual-health"); props.removeAllSections("devices", "sexual-health");} }
          >
            Sexual Health Test
          </button>
          <button
            type="button"
            data-filter
            data-filter-type="devices"
            data-target="respiratory"
            className="filter"
            onClick={() => { props.filterFunction("devices", "respiratory"); props.removeAllSections("devices", "respiratory");} }
          >
            Respiratory Health Test
          </button>
        </div>
      </Container>
    </StyledHero>
  )
}

export default Hero
